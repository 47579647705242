import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { media } from "../helpers";

const TopBannerContainer = styled.section`
  position: relative;

  .image-container {
    max-height: 70vh;
    overflow: hidden;

    .gatsby-image-wrapper {
      height: 70vh;
      ${media.medium`height: auto;`}

      img {
        ${media.medium`top: -320px !important;`}
      }
    }

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .content-container {
      position: absolute;
      top: 30vh;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 0 20px;
      ${media.medium`padding: 0; top: 32vh;`}

      h1 {
        color: #fff;
        font-size: 21px;
        margin-bottom: 15px;
        font-family: "Jost", sans-serif;
        ${media.small`font-size: 35px;`}
        ${media.medium`font-size: 50px; word-spacing: 10px;`}
      }

      p {
        font-size: 16px;
        font-family: "Jost", sans-serif;
        max-width: 630px;
        margin: 0 auto;
        line-height: 1.5;
        color: #fff;
        ${media.medium`font-size: 21px;`}
      }
    }
  }

  &.half {
    .image-container {
      max-height: 50vh;
      overflow: hidden;

      img {
        top: 0px !important;
      }

      .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        background-color: rgba(0, 0, 0, 0.25);
      }

      .content-container {
        position: absolute;
        top: 27vh;
        left: 0;
        width: 100%;
        text-align: center;

        h1 {
          color: #fff;
          font-size: 50px;
          margin-bottom: 10px;
          font-family: "Jost", sans-serif;
        }

        p {
          color: #fff;
          font-size: 19px;
          font-family: "Jost", sans-serif;
        }
      }
    }
  }
`;

const TopBanner = ({ title, caption, image, size }) => {
  let classes = [];
  if (size) {
    classes.push(size);
  }
  return (
    <TopBannerContainer className={classes}>
      <div className="image-container">
        <Img fluid={image} />
        <div className="overlay"></div>
        <div className="content-container">
          <h1>{title}</h1>
          {caption && <p>{caption}</p>}
        </div>
      </div>
    </TopBannerContainer>
  );
};

export default TopBanner;
