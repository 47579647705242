import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Wrapper from "../elements/wrapper";
import { media, TransitionMixin } from "../helpers";

import gratitude from "../../images/resources/Gratitude.pdf";
import mindfulness from "../../images/resources/mindfulness.pdf";
import optimism from "../../images/resources/optimism.pdf";
import sleephyg from "../../images/resources/Sleephygiene.pdf";

const ResourcesGridContainer = styled.section`
  padding: 0 0 70px;
  .title-container {
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    ${media.medium`text-align: left;`}

    h4 {
      margin: 0;
      font-style: italic;
      text-transform: uppercase;
      font-size: 16px;
      z-index: 15;
      background-color: #fff;
      display: inline-block;
      position: relative;
      padding: 0 20px;

      ${media.medium`padding-right: 20px; padding-left: 0px;`}
    }
    &::after {
      content: " ";
      background-color: #efefef;
      height: 1px;
      position: absolute;
      top: 12px;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  }
`;

const ResourcesGrid = ({ resourcesRef }) => {
  const data = useStaticQuery(graphql`
    query {
      gratitude: file(relativePath: { eq: "gratitude.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sleep: file(relativePath: { eq: "sleep.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mindfulness: file(relativePath: { eq: "mindfulness.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      optimisim: file(relativePath: { eq: "optimisim.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  let gridData = [
    {
      title: "Gratitude",
      caption: "",
      image: data.gratitude.childImageSharp.fluid,
      link: gratitude,
    },
    {
      title: "Mindfulness",
      caption: "",
      image: data.mindfulness.childImageSharp.fluid,
      link: mindfulness,
    },
    {
      title: "Optimisim",
      caption: "",
      image: data.optimisim.childImageSharp.fluid,
      link: optimism,
    },
    {
      title: "Sleep Hygiene",
      caption: "",
      image: data.sleep.childImageSharp.fluid,
      link: sleephyg,
    },
  ];

  return (
    <ResourcesGridContainer ref={resourcesRef}>
      <Wrapper>
        <div className="title-container">
          <h4>Patient Resources</h4>
        </div>
      </Wrapper>
      <Wrapper blockFlex className="grid-item-wrapper">
        {gridData.map(item => {
          return (
            <ResourceItem
              title={item.title}
              caption=""
              image={item.image}
              link={item.link}
            />
          );
        })}
      </Wrapper>
    </ResourcesGridContainer>
  );
};

const ResourceItemContainer = styled.div`
  flex: 0 0 25%;
  margin-bottom: 40px;
  ${media.medium`margin-bottom: 0px;`}

  &:last-child {
    margin-bottom: 0px;
  }

  a {
    text-decoration: none;
    color: #4a4a4a;
    &:hover {
      .inner-wrap {
        webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
          0 9px 46px 8px rgba(0, 0, 0, 0.12),
          0 11px 15px -7px rgba(0, 0, 0, 0.2);
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
          0 9px 46px 8px rgba(0, 0, 0, 0.12),
          0 11px 15px -7px rgba(0, 0, 0, 0.2);
      }
    }
    > .inner-wrap {
      max-width: 90%;
      margin: 0 auto;
      ${TransitionMixin(".25s")}

      .content-container {
        border: 1px solid #efefef;
        border-top: 0px;
        .inner-wrap {
          padding: 20px;

          h4 {
            margin: 0;
          }

          p {
            margin: 0;
            color: #777;
          }
        }
      }
    }
  }
`;

const ResourceItem = ({ title, image, link }) => {
  return (
    <ResourceItemContainer>
      <a target="_blank" href={link ? link : ""}>
        <div className="inner-wrap">
          <div className="image-container">
            <Img fluid={image} />
          </div>
          <div className="content-container">
            <div className="inner-wrap">
              <h4>{title}</h4>
              {/* <p>A short caption describing the resource</p> */}
            </div>
          </div>
        </div>
      </a>
    </ResourceItemContainer>
  );
};

export default ResourcesGrid;
