import React from "react";
import styled from "styled-components";

import Wrapper from "./wrapper";
import { media } from "../helpers";

const LicensesContainer = styled.div`
  padding: 70px 0;
  background-color: #000;
  .title-container {
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    ${media.medium`text-align: right;`}

    h4 {
      margin: 0;
      font-style: italic;
      text-transform: uppercase;
      font-size: 16px;
      z-index: 15;
      background-color: #000;
      display: inline-block;
      position: relative;
      padding: 0 20px;
      color: #fff;

      ${media.medium`padding-left: 20px; padding-right: 0px;`}
    }
    &::after {
      content: " ";
      background-color: #efefef;
      height: 1px;
      position: absolute;
      top: 12px;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  }

  ul {
    margin: 0;
    list-style: none;
    ${media.medium`display: flex;flex-wrap: wrap;`}
    li {
      flex: 0 0 33.33%;
      text-align: center;
      font-size: 17px;
      color: #fff;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

const Licenses = () => {
  return (
    <LicensesContainer>
      <Wrapper>
        <div className="title-container">
          <h4>Licenses and Certifications</h4>
        </div>
        <ul>
          <li>NYS Licensed Psychologist </li>
          <li>Certified Cognitive Behavioral Therapist (CBT)</li>
          <li>Post-graduate Fellow Child/Adolescent Neuropsychologist</li>
          <li>Diplomat School Neuropsychology ABSNP</li>
          <li>Substance Abuse Professional (SAP) NAADAC</li>
          <li>Master Addiction Counselor (MAC) NCC AP</li>
        </ul>
      </Wrapper>
    </LicensesContainer>
  );
};

export default Licenses;
