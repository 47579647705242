import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Wrapper from "./wrapper";
import { media, TransitionMixin } from "../helpers";

const SplitSectionContainer = styled.section`
  border-top: 1px solid #efefef;
  .image-container {
    flex: 1;
    .gatsby-image-wrapper {
      max-height: 300px;
      ${media.medium`max-height: 700px;`}
    }
  }
  .content-container {
    flex: 1;
    display: flex;
    align-items: center;
    .inner-wrap {
      padding: 20px 5vw;
      ${media.medium`padding: 0 5vw;`}
      h2 {
        font-size: 32px;
        margin: 0 0 10px;
        color: #4a4a4a;
        ${media.medium`font-size: 45px;`}
      }

      p {
        color: #777;
        margin: 0;

        line-height: 1.5;
        font-size: 15px;
        ${media.medium`font-size: 17px;`}
      }

      .form-container {
        margin-top: 20px;

        form {
          .input-container {
            display: flex;
            margin-bottom: 15px;
            input {
              flex: 1;
              border: 1px solid #ccc;
              height: 40px;
              padding-left: 10px;
              border-radius: 4px;
              font-size: 14px;
            }

            textarea {
              flex: 1;
              border: 1px solid #ccc;
              height: 140px;
              padding-left: 10px;
              border-radius: 4px;
              font-size: 14px;
            }

            button {
              text-transform: uppercase;
              font-size: 12px;
              color: #fff;
              background-color: #4a4a4a;
              border: 1px solid #4a4a4a;
              font-weight: bold;
              padding-left: 10px;
              border-radius: 4px;
              font-size: 14px;
              min-width: 120px;
              ${TransitionMixin(".25s")}

              &:hover {
                cursor: pointer;
                color: #4a4a4a;
                background-color: #fff;
              }
            }

            &.split {
              input {
                &:first-child {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SplitSection = ({ contactRef }) => {
  const data = useStaticQuery(graphql`
    query {
      splitImage: file(relativePath: { eq: "split-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <SplitSectionContainer ref={contactRef}>
      <Wrapper blockFlex size="fw">
        <div className="image-container">
          <Img fluid={data.splitImage.childImageSharp.fluid} />
        </div>

        <div className="content-container">
          <div className="inner-wrap">
            <h2>By Appointment Only</h2>
            <p>
              Please share a brief description of your concerns and goals. All
              submissions are confidential and subject to HIPPA Compliance
              regulations:
            </p>
            <div className="form-container">
              <form
                name="home-page-form"
                method="POST"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className="form"
              >
                <input className="hidden" name="bot-field" type="hidden" />
                <input
                  className="hidden"
                  type="hidden"
                  name="form-name"
                  value="home-page-form"
                />
                <div className="input-container split">
                  <input required name="name" type="text" placeholder="Name" />
                  <input
                    required
                    name="email"
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="input-container">
                  <input type="text" placeholder="Phone Number" />
                </div>
                <div className="input-container">
                  <input
                    required
                    type="text"
                    name="insurance-carrier"
                    placeholder="Insurance Carrier (or N/A)"
                  />
                </div>
                <div className="input-container">
                  <textarea
                    required
                    name="inquiry"
                    placeholder="Inquiry"
                  ></textarea>
                </div>
                <div className="input-container">
                  <button type="submit">Submit</button>
                </div>
                <p>*Accepts most Major Insurance Plans </p>
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    </SplitSectionContainer>
  );
};

export default SplitSection;
