import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Wrapper from "./wrapper";
import { media } from "../helpers";

const LocationsSectionContainer = styled.section`
  padding: 0 0 70px;

  .title-container {
    text-align: center;
    margin-bottom: 30px;
    h3 {
      font-size: 28px;
      margin: 0;
      color: #4a4a4a;
      ${media.medium`font-size: 36px;`}
    }

    p {
      color: #777;
      max-width: 500px;
      margin: 10px auto 0;
      font-size: 17px;
      line-height: 1.5;
    }
  }

  .location-wrapper {
    .item-container {
      flex: 0 0 50%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0px;
      }

      > .inner-wrap {
        max-width: 90%;
        margin: 0 auto;

        .image-container {
          .gatsby-image-wrapper {
            max-height: 250px;
            ${media.large`max-height: 350px;`}
            ${media.xl`max-height: 450px;`}
          }
        }
        .content-container {
          text-align: center;
          margin-top: 10px;

          h4 {
            margin: 0;
            font-size: 21px;
            ${media.medium`font-size: 32px;`}
          }
          p {
            color: #777;
            margin: 5px auto 0;
          }
        }
      }
    }
  }
`;

const LocationsSection = () => {
  const data = useStaticQuery(graphql`
    query {
      leftImage: file(relativePath: { eq: "left-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rightImage: file(relativePath: { eq: "right-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <LocationsSectionContainer>
      <Wrapper>
        <div className="title-container">
          <h3>Locations</h3>
          <p>
            Offering clinical, substance use and neuropsychological services by
            appointment only.
          </p>
        </div>
      </Wrapper>
      <Wrapper blockFlex className="location-wrapper">
        <div className="item-container">
          <div className="inner-wrap">
            <div className="image-container">
              <Img fluid={data.leftImage.childImageSharp.fluid} />
            </div>
            <div className="content-container">
              <div className="inner-wrap">
                <h4>POUGHKEEPSIE, NY</h4>
                <p>
                  <em>By appointment only</em>
                </p>
                <p>(845) 471-8175</p>
              </div>
            </div>
          </div>
        </div>
        <div className="item-container">
          <div className="inner-wrap">
            <div className="image-container">
              <Img fluid={data.rightImage.childImageSharp.fluid} />
            </div>
            <div className="content-container">
              <div className="inner-wrap">
                <h4>HOPEWELL JCT, NY</h4>
                <p>
                  <em>By appointment only</em>
                </p>
                <p>(845) 227-6918</p>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </LocationsSectionContainer>
  );
};

export default LocationsSection;
