import React, { useRef } from "react";
import { Link, useStaticQuery } from "gatsby";
import { window } from "browser-monads";

import Layout from "../components/layout";
import TopBanner from "../components/elements/banner";
import BasicCTA from "../components/elements/ctas";
import ResourcesGrid from "../components/elements/resources-grid";
import SplitSection from "../components/elements/split-section";
import LocationsSection from "../components/elements/locations-section";
import Licenses from "../components/elements/licenses";
import SEO from "../components/seo";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      topBannerDesktop: file(relativePath: { eq: "slide-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      seoImage: file(relativePath: { eq: "seo.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);

  const aboutRef = useRef(null);
  const resourcesRef = useRef(null);
  const contactRef = useRef(null);
  return (
    <Layout
      contactRef={contactRef}
      resourcesRef={resourcesRef}
      aboutRef={aboutRef}
    >
      <SEO title="Home">
        <meta
          name="og:image"
          content={
            window.location.origin + data.seoImage.childImageSharp.original.src
          }
        />
        <meta
          name="image"
          content={
            window.location.origin + data.seoImage.childImageSharp.original.src
          }
        />
      </SEO>

      <TopBanner
        title="Insight &middot; Understanding &middot; Support"
        caption="Specializing in Anxiety and Panic Disorders,
        Depression, ADHD, PTSD,
        Cognitive Behavioral &amp; Supportive Therapy        
        "
        image={data.topBannerDesktop.childImageSharp.fluid}
      />

      <BasicCTA aboutRef={aboutRef} />
      <LocationsSection />
      <ResourcesGrid resourcesRef={resourcesRef} />
      <SplitSection contactRef={contactRef} />
      <Licenses />
    </Layout>
  );
};

export default IndexPage;
