import React from "react";
import styled from "styled-components";

import Wrapper from "../elements/wrapper";
import { media } from "../helpers";

const BasicCTAContainer = styled.section`
  padding: 70px 0;

  .cta-wrapper {
    text-align: center;
    .left-container {
      margin-bottom: 40px;
      p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 auto;
        max-width: 650px;
        ${media.medium`font-size: 19px;`}
      }
    }
    .right-container {
      p {
        margin: 0 0 20px;
        font-size: 16px;
        font-weight: bold;
        ${media.medium`font-size: 17px;`}
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        ${media.medium`display: flex;flex-wrap: wrap;`}
        li {
          font-size: 24px;
          color: #777;
          flex: 0 0 33.33%;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      &.second {
        margin-top: 50px;

        margin: 50px auto 0;
        ${media.medium`max-width: 70vw;`}
        p {
          font-size: 24px;
        }
        ul {
          ${media.medium`display: flex; flex-wrap: wrap;`}
          li {
            text-align: left;
            margin-bottom: 30px;
            font-size: 16px;
            flex: 0 0 50%;

            span {
              max-width: 80%;
              margin: 0 auto;
              display: block;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
`;

const BasicCTA = ({ aboutRef }) => {
  return (
    <BasicCTAContainer ref={aboutRef}>
      <Wrapper size="sm" className="cta-wrapper">
        <div className="left-container">
          <p>
            Individual Therapy provides the opportunity to openly work though
            challenging issues in a supportive, caring and confidential
            environment.
          </p>
        </div>
        <div className="right-container">
          <div className="inner-wrap">
            <p>Additional Treatment Concerns:</p>
            <ul>
              <li>Adjustment to Life Changes</li>
              <li>Dealing with Life Stresses</li>
              <li>Relationship Difficulties </li>
              <li>Work & Career Issues</li>
              <li>Stress Management</li>
              <li>Retirement Transitions</li>
            </ul>
          </div>
        </div>
        <div className="right-container second">
          <div className="inner-wrap">
            <p>Specializations include:</p>
            <ul>
              <li>
                <span>
                  <strong>Neuropsychological Evaluations</strong> for children,
                  teens and young adults who have cognitive and academic
                  difficulties, such as ADHD, Dyslexia and other Learning
                  Disorders including social, emotional and behavioral
                  challenges.
                </span>
              </li>
              <li>
                <span>
                  <strong>Federal & OASAS Substance Abuse Evaluations</strong>{" "}
                  for court ordered or other legal concerns, including DWI etc.
                </span>
              </li>
              <li>
                <span>
                  <strong>
                    Mental Health Evaluations for legal and employment purposes
                  </strong>
                </span>
              </li>
              <li>
                <span>
                  <strong>Bariatric Clearance Assessments</strong>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Wrapper>
    </BasicCTAContainer>
  );
};

export default BasicCTA;
